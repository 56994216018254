var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VuePureLightbox',{staticClass:"lightbox"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('section',{staticClass:"reset has-text-centered"},[_c('div',{staticClass:"container"},[_c('table',{staticClass:"reg-form has-text-centered"},[_c('tr',[_c('td',{staticClass:"form-header"},[_c('p',{staticClass:"org-description is-size-5"},[_vm._v("Reset your password:")])])]),_c('tr',[_c('td',{staticClass:"form-content"},[_c('table',{staticClass:"field-table"},[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"rules":{ regex: /(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/ },"vid":"password","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Password","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"min-length":"8","type":"password","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","vid":"Confirmation","name":"Password Confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Confirm Password","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.submit)}},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})],1)]}}],null,true)})],1)])]),_c('button',{staticClass:"button submit is-primary is-outlined",on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('span',[_vm._v("Submit")])])])])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }