<template>
    <VuePureLightbox class="lightbox">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <section class="reset has-text-centered">
                <div class="container">
                    <table class="reg-form has-text-centered">
                    <tr>
                        <td class="form-header">
                            <p class="org-description is-size-5">Reset your password:</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="form-content">
                            <table class="field-table">
                                <tr>
                                    <td>
                                        <ValidationProvider :rules="{ regex: /(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/ }" vid="password" name="Password" v-slot="{ errors, valid }">
                                            <b-field label="Password" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input min-length="8" type="password" v-model="password" password-reveal >
                                                </b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </td>
                                    <td>
                                        <ValidationProvider rules="required|confirmed:password" vid="Confirmation" name="Password Confirmation" v-slot="{ errors, valid }">
                                            <b-field label="Confirm Password" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                                                <b-input type="password" v-model="confirmation" password-reveal v-on:keyup.native.enter="handleSubmit(submit)">
                                                </b-input>
                                            </b-field>
                                        </ValidationProvider>
                                        
                                    </td>
                                </tr>
                            </table>
                            <button class="button submit is-primary is-outlined" @click="handleSubmit(submit)">
                                <span>Submit</span>
                            </button>
                        </td>
                    </tr>
                    </table>
                </div>
            </section>
        </ValidationObserver>
    </VuePureLightbox>    
</template>

<script>
    import { ValidationObserver, ValidationProvider } from "vee-validate";

    export default {
        name: "reset",
        components: {
            ValidationObserver,
            ValidationProvider
        },
        props: {
            tk: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                password: "",
                confirmation: "",
                message: ""
            }
        },
        computed: {
            loggedIn() {
                console.log("Login State: " + this.$store.state.auth.status.loggedIn);
                return this.$store.state.auth.status.loggedIn;
            },
            token() {
                return this.$store.state.auth.user.token;
            }
        },
        created() {
            if (this.loggedIn && this.loggedIn == true) {
            console.log("redirect...");
            this.$router.replace('/dashboard/profile');
            }
        },
        methods: {
            submit() {
                requestAnimationFrame(() => {
                    this.load();
                    axios.defaults.headers.common['Authorization'] = "Bearer " + this.tk;
                    axios.post(
                                process.env.VUE_APP_API + "/v1/user/reset", 
                                { password: this.password },
                                { useCredentials: true }
                            )
                            .then((res) => {
                                if (res.data.status == true) {
                                    console.log("Password Reset!");
                                    this.$router.replace({ path: "/resetdone/1" });
                                } else {
                                    console.log("Password reset failed!");
                                    console.log(res.data)
                                    this.$router.replace({ path: "/resetdone/2" });
                                }
                            })
                            .catch((error) => {
                                console.log("Can't connect to server for password reset!");
                                console.log(error)
                                this.$router.replace({ path: "/resetdone/3" });
                            });
                });
            },
            load() {
                let loader = this.$loading.show({
                    container: this.fullPage,
                    onCancel: this.onCancel,
                    active: this.isLoading
                });
                setTimeout(() => {
                    loader.hide()
                },1000)
            },
        }
    };
</script>

<style lang="scss">
    $main-color: #aac734;
    .lightbox {
        width: 100%; height: 100%;
        background: rgba(0, 0, 0, .4);
    }
    .field-table {
        margin-bottom: 10px;
    }
    .field-table th,td {
        padding-left: 5%;
        padding-right: 5%;
    }
    .email-table {
        margin-left: 8px;
    }
    .plan {
        width: 35%;
        padding-right: 15px;
    }
    .plan-select {
        width: 100%;
        padding-right: 0px;
    }
    .last-ft {
        margin-bottom: 20px;
    }
    .reset {
        padding-top: 25px;
        padding-bottom: 100px;
        width: 100%;
    }
    .container {
        width: 100%;
        justify-content: center;
    }
    .disclaimer {
        font-size: 0.78rem;
        font-weight: 400;
    }
    .fa-check {
        color: $main-color;
    }
    .is-success {
        color: $main-color;
    }
    .select {
        color: $main-color;
    }
    .select.is-success select {
        color: $main-color;
        border-color: $main-color;
    }
    .select.is-success select:hover {
        border-color: $main-color;
    }
    .select.is-success:not(:hover)::after {
        border-color: $main-color;
    }
    .select:not(.is-multiple):not(.is-loading):hover::after {
        border-color: $main-color;
    }
    .select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
        box-shadow: rgba(170, 199, 52, 0.25);
    }
    .is-success.input {
        color: $main-color;
        border-color: $main-color;
    }
    .has-text-success {
        color: $main-color;
    }
    .submit {
        margin-top: 20px;
        text-align: center;
    }
    .reg-form {
        width: 100%;
        max-width: 600px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
        margin-left: auto;
        margin-right: auto;
        margin-top: 75px;
        padding-right: 10px;
    }
    .form-header {
        font-weight: bold;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;
        background: $main-color;
    }
    .form-content {
        background: #F5F5F5;
        padding: 30px;
        padding-left: 15%;
        padding-right: 15%;
    }
    .form-content .label {
        font-size: 0.78rem;
        text-align: left;
        padding-left: 5px;
        font-weight: 400;
        max-width: 600px;
    }
</style>